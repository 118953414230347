#screen{

    --ion-background-color:#070707;
  }
  

.tile25{
    background-size:cover;
    flex:1
}
.tile50{
    background-size:cover;
    flex:2
}
.tileRow{
    flex:1;
    display: flex;
    flex-direction: row;
}
.page{
    background-size:cover; 
}
.heroTitle{
    color:white;
    font-weight:600;
    margin-top:-1vh;
    text-shadow: -2px -2px 0 rgba(0, 0, 0, 0.3);

}

.cardContainer{
    height:calc(100% - 1vh);
    position:relative; 
    background-color:#ccc; 
    width:14vw;
    overflow:hidden;
    border-radius:.5vw;
    margin:.5vw;
    background-size:cover;
}
.cardTitle{
    position:absolute; 
    bottom:0;
    left:0; 
    width:100%;
    height:8vh; 
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(0, 0, 0, 0.767) 70%);
}
.cardDetails{
    position: absolute;
    bottom: .3vw;
    left: .6vw;
}
.cardName{
    font-weight: 900;
    color: white;
    font-size: 1.3vw;
}
.cardDate{
    color: white;
}

#myVideo {
    position: fixed;
    right: 0;
    bottom: 0;
    min-width: 100%;
    min-height: 100%;
  }
.Screen_exploreBox{
    position: fixed;
    bottom: 0px;
    right: 0px;
    z-index: 999;
    background-color: black;
    width: 10vw;
    height: 10vh;
    font-size: .75vw;
    text-align: center;
    padding-top: 1vh;
    color: white;
    font-weight: bold;
    border-radius: 18px 0 0 0 ;
}