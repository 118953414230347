#list{
  --ion-background-color:#070707;
}


/* List Items Details */
.List_item_details_box {
  color:white;
  height: 70vh;
  margin: -10px -10px 0px -10px;
}
.List_item_details_title {
  font-size: 1.8vw;
  padding-top: 7vh;
  padding-left: 2vw;
  font-weight: 600;
}
.List_item_details_dates {
  display: inline-flex;
  flex-direction: row;
  padding-left: 1vw;
  padding-right: 1.1vw;
  width: 100%;
}
.List_item_details_dates_box {
  flex: 1;
  border: 3px solid rgb(155, 155, 155);
  margin: 1vw;
  padding: .8vw;
  text-align: center;
  font-size: 1vw;
}
.List_item_details_dates_date {
  font-weight: 600;
  font-size: 1.6vw;
}
.List_item_details_address {
  margin-left: 2vw;
  font-size: 1vw;
  padding-bottom: 1vh;
  width: calc(100% - 4vw);
  border-bottom: 3px solid rgb(155, 155, 155);
}
.List_item_details_text {
  font-size: 1vw;
  margin-left: 2vw;
  padding-top: 2vh;
  width: calc(100% - 4vw);
}

/* List Item Image */
.List_item_image_box {
  height: 70vh;
  margin: -10px -10px 0px -10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* List Items */
.List_items_title {
  color: white;
  font-size: 1.8vw;
  padding-left: 0px;
  font-weight: 600;
}
.List_items_box {
  width: 100%;
  height: calc(30vh - 10px);
  display: inline-flex;
  flex-direction: column;
  padding-left: 1vw;
  overflow-x: scroll;
  overflow-y: hidden;
  white-space:nowrap;
}
.List_items_item_box {
  display: inline-flex;
  flex-direction: column;
  color: white;
  width: 14vw;
  height: 20vh;
  margin: 1vh .5vw;
  padding: 0px;
  border-radius: .7vw;
  background-size: cover;
  position: relative;
  white-space:pre-wrap !important;
}
.List_items_item_title_box {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  padding: .4vw;
}
.List_items_item_title_box_gradient {
  height: 100%;
  border-radius: .65vw;
  background: #000000;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 0, 0.904) 100%
  );
}
.List_items_item_title {
  font-weight: 600;
  font-size: 1vw;
}
.List_items_item_dates {
  font-size: .6vw;
  font-style: italic;
}

/* Back Button */
.List_backButton {
  position: fixed;
  top: 10px;
  left: 2vw;
  font-size: 3vw;
  color: white;
}

::-webkit-scrollbar-thumb {
  background: #ffffff1e;
}
::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}