#listtv{
  --ion-background-color:#070707;
}


/* List Items Details */
.ListTv_item_details_box {
  color:white;
  height: 35vh;
  margin: -10px -10px 0px -10px;
}
.ListTv_item_details_title {
  font-size: 1.8vw;
  padding-top: 7vh;
  padding-left: 2vw;
  font-weight: 600;
}
.ListTv_item_details_dates {
  display: inline-flex;
  flex-direction: row;
  padding-left: 1vw;
  padding-right: 1.1vw;
  width: 100%;
}
.ListTv_item_details_dates_box {
  flex: 1;
  border: 3px solid rgb(155, 155, 155);
  margin: 1vw;
  padding: .8vw;
  text-align: center;
  border-radius: 8px;
  font-size: 1vw;
}
.ListTv_item_details_dates_date {
  font-weight: 600;
  font-size: 1.6vw;
}
.ListTv_item_details_address {
  margin-left: 2vw;
  font-size: 1.5vw;
  padding-bottom: 1vh;
  width: calc(100% - 4vw);
  border-bottom: 3px solid rgb(155, 155, 155);
}
.ListTv_item_details_text {
  font-size: 1.5vw;
  margin-left: 2vw;
  padding-top: 2vh;
  width: calc(100% - 4vw);
}

/* List Item Image */
.ListTv_item_image_box {
  height: 45vh;
  margin: -10px -10px 0px -10px;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

/* List Items */
.ListTv_items_title {
  color: black;
  text-align: center;
  font-size: 1.8vw;
  padding-left: 0px;
  font-weight: 600;
}

.ListTv_items_item{
  display: inline-flex;
  flex-direction: row;

}
.ListTv_items_item_box:focus{
 border: 10px #afafaf solid;
  outline: 0px;
}

.ListTv_items_box:focus{
   outline: 0px;
 }

.ListTv_items_list{
  display: inline-flex;
  flex-direction: column;
}

.ListTv_items_box {
  width: 100%;
  height: calc(100vh);
  display: inline-flex;
  flex-direction: column;
  padding-left: 1vw;
  overflow-y: scroll;
  overflow-x: hidden;
  white-space:nowrap;
}
.ListTv_items_item_box {
  display: flex;
  flex-direction: column;
  color: white;
  width: calc(100% - 20px);
  height: 30vh;
  margin: 1vh .5vw;
  padding: 0px;
  border-radius: .7vw;
  background-size: cover;
  position: relative;
  white-space:pre-wrap !important;
}
.ListTv_items_item_title_box {

  width: 100%;
  padding: .4vw;
}
.ListTv_items_item_title_box_gradient {
  height: 100%;
  border-radius: .65vw;
  background: linear-gradient(
    0deg,
    #0707075b 65%,
    #070707da 85%
  );
}
.ListTv_items_item_title {
  font-weight: 600;
  font-size: 1vw;
}
.ListTv_items_item_dates {
  display: flex;
  flex-direction: column;
  margin-right: 30px;
  font-size: 2vw;
  color: white;
  font-style: italic;
  width: 3.5vw;
  height: calc(100% - 20px);
  align-self: center;
  
  border-radius: 20px;
  padding: 5px;
}

/* Back Button */
.ListTv_backButton {
  position: fixed;
  top: 10px;
  left: 2vw;
  font-size: 3vw;
  color: white;
}
.VideoFrame{
  position:fixed;
  border-radius: 0 20px 0 0;
  bottom: 0;
  left:0;
  height:60px;
  width:90px;
  background-color:black;
  overflow:hidden;
  border-top: #545454 solid 0px;
  border-right: #545454 solid 0px;
}