
.tile25{
        background-size:cover;
    flex:1
}
.tile50{

    background-size:cover;
    flex:2
}
.tileRow{
    flex:1;
    display: flex;
    flex-direction: row;
}
.background{
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    box-shadow:  0px 0px 20px 0px #000000;
}
.heroTextDiv{
    padding-top: 25vh;
    font-size: 40px;
    color:white;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
}
.heroText{
    background-color: rgba(0, 0, 0, 0.445);
    border-radius: 3px;
    padding: 5px 15px 7px 15px;
}
.heroSubDiv{
    padding-top: 25vh;
    font-size: 40px;
    color:white;
    font-weight: 900;
    display: flex;
    justify-content: center;
    align-items: center;
}
.heroSub{
    background-color: rgba(0, 0, 0, 0.445);
    border-radius: 3px;
    padding: 5px 15px 7px 15px;
}
.Tv_imageCard{
    height: 200px;
    width: 350px;
    margin: 10px;
    border-radius: 10px;
    display: inline-block;
    align-items: flex-end;
    padding:10px;
    background-position: center;
    background-size: cover;
}
.Tv_imageCardContent{
    display: flex;
    height: 100%;
    align-items: flex-end;
    flex: 1;

}
ion-button:focus{
    border: 5px #000000 solid;
    border-radius: 40px;
    outline: 0px;
}

ion-select.fullSelect{
    width: 100%;
    max-width: 100%;
  }


  .item-content {
    background-color: #ffffff00;
  }
  .item .item-content {
    background-color: transparent !important;
  }
  .Ads_img {
    width: 100%;
  }
  .Ads_imgCol {
    overflow: hidden;
    height: 50;
  }
  .Ads_modal {
    background-color: white;
  }
  .Ads_modal_container {
    background-color: white;
  }
  .Ads_modal_close {
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
  }
  .notificationText{
    color: #222222;
    margin: -15px 20px 0px 20px;
    background-color: #ffffff00;
    overflow: hidden;
        text-overflow: ellipsis;
        max-height: 110px;
        margin-bottom: 10px;
  }
  .drawer{
    height: 100vh;
    background-color: white !important;
  }
  .drawer-div{
    height: 100vh;
  background-color: #e3e3e3 !important;
  }
  
  .feedFab{
    position: fixed;
    top: 40px;
    right:20px;
  }
  
  .bottomDrawerButtonBar{
    height:5px;
    width: 20%;
    margin-top: 18px;
    margin-left: 40%;
    border-radius: 10px;
    background-color: #e3e3e3;
  }
  .bottomBarLabel{
    font-size: small;
    font-weight: 600;
  }
  
  .bottomBarLabelCol{
    margin-top: -5px;
  }
  
  .Feed_Item_image{
    width: 100%;
    height: 100%;
    background-size: cover;
    border-radius: 14px;
    box-shadow: 0px 0px 15px 5px rgba(0,0,0,0.23);
  }
  
  .eventCard{
    position: relative;
    margin: 20px;
    box-shadow: 0px 0px 15px 5px rgba(0, 0, 0, 0.098);
    border-radius: 16px;
    overflow: hidden;
    height:180px;
  }
  .eventImage{
    width: 100%;
  }
  .eventText{
    width:100%;
    background-color: #222222;
   position: absolute;
   padding:5px 2px 5px 8px;
  
   color: white;
    bottom: 0px;
    left: 0px;
  }
  .eventDates{
    top:10px;
    right:10px;
    width: 60px;
    height: 60px;
    position: absolute;
    background-color: #ffffff;
    border: #222222 2px solid;
    border-radius: 10px;
    text-align: center;
  }
  .buttonsBottomDiv{
    position: fixed;
    bottom: 50px;
    right: 0;
    width: 100%;
    height: 60px;
    text-align: center;
  
  }
  .eventTitle{
    font-size: large;
    font-weight: 600;
    margin-top: 3px;
  }