ion-menu {
    max-width: 300px!important;
    border: 0px;
    background-color: transparent !important;
}

ion-content.admin{
    --ion-background-color:#eee !important;
}

 ion-list{
     background-color: transparent !important;
 }
 ion-menu-toggle{
     background-color: transparent !important;
 }


ion-content.menu{
    --ion-background-color:rgb(255, 255, 255) !important;
}

.input { 
 border: solid rgb(186, 186, 186) 1px;
 padding: 8px 8px 8px 10px;
 border-radius: 25px;
 width: 100%;
 margin-bottom: 10px;
 background-color:white;
}

.content-card{
    background-color:white; 
    border-radius:10px; 
    padding:20px;
    margin-top: 20px;
}
.first{
    margin-top: 60px;
}
.unpublished{
    color: rgb(143, 143, 143);
}

.image-search-item{
    width: 45%;
    margin: 10px;
    border-radius: 10px;
    padding-bottom: -10px;
    overflow: hidden;
    display: inline-block;
}
.bigNumber{
    text-align: center;
    font-size: 70px;
    font-weight: 700;
}