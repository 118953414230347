#listtvport{
  --ion-background-color:#070707;
transform: rotate(90deg) translateX(-100%);
  position: fixed;
  top:61vh;
  right: 36vh;
  width:102vh !important;
  height: 102vw;

}


/* List Items Details */
.ListTvPort_item_details_box {
  color:white;
  height: 30vw;
  margin: -10px -10px 0px -10px;
 
}
.ListTvPort_item_details_title {
  font-size: 4vh;
  text-align: center;
  padding-top: 1vw;
  padding-bottom: 1vw;
  padding-left: 2vh;
  font-weight: 600;
}
.ListTvPort_item_details_dates {
  display: inline-flex;
  flex-direction: row;
  padding-left: 1vh;
  padding-right: 1.1vh;
  width: 100%;

}
.ListTvPort_item_details_dates_box {
  flex: 1;
  border: 3px solid rgb(155, 155, 155);
  margin: 1vw;
  padding: .8vw;
  text-align: center;
  border-radius: 8px;
  font-size: 2vh;
}
.ListTvPort_item_details_dates_date {
  font-weight: 600;
  font-size: 3vh;
}
.ListTvPort_item_details_address {
  margin-left: 4vh;
  font-size: 2.5vh;
  padding-bottom: 1vw;
  width: calc(100% - 8vh);
  border-bottom: 3px solid rgb(155, 155, 155);
}
.ListTvPort_item_details_text {
  font-size: 2.5vh;
  margin-left: 4vh;
  padding-top: 1vw;
  width: calc(100% - 8vh);
}

/* List Item Image */
.ListTvPort_item_image_box {
  height: 45vw;
  margin: -10px -10px 0px -10px;
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;

}

/* List Items */
.ListTvPort_items_title {
  color: black;
  text-align: center;
  font-size: 2vh;
  padding-left: 0px;
  font-weight: 600;
}

.ListTvPort_items_item{
  width:40vh;
  display: inline-flex;
  flex-direction: column;
}

.ListTvPort_items_list{
  display: inline-flex;
  flex-direction: row;
}

.ListTvPort_items_box {
  width: 100vh;
  display: inline-flex;
  flex-direction: row;
  padding-left: 1vh;
  overflow-y: scroll;
  overflow-x: hidden;
}
.ListTvPort_items_item_box {
  display: flex;
  flex-direction: column;
  color: white;
  width: calc(100% - 20px);
  height: 15vw;
  margin: 1vw .5vh;
  padding: 0px;
  border-radius: .7vh;
  background-size: cover;
  position: relative;
  white-space:pre-wrap !important;
}
.ListTvPort_items_item_title_box {
  
}
.ListTvPort_items_item_title_box_gradient {
  height: 100%;
  border-radius: .65vw;
  background: linear-gradient(
    0deg,
    #0707075b 65%,
    #070707da 85%
  );
}
.ListTvPort_items_item_title {
 
  font-style:normal ;
}
.ListTvPort_items_item_dates {
  font-size: 1.5vw;
  color: white;
  font-style: italic;
  border-radius: 20px;
  padding: 5px;
}

/* Back Button */
.ListTvPort_backButton {
  position: fixed;
  top: 10px;
  left: 2vw;
  font-size: 3vw;
  color: white;
}
