.NewForm_input {
    padding: 6px;
    border: 1px #ccc solid;
    border-radius: 5px;
  }
  .NewForm_input_container {
    padding-top: 10px;
  }
  

  .form { 
    border: solid 1px rgb(207, 207, 207);
    border-radius: 15px;
	max-width: 800px; 
    background-color: #fff;
	margin: 20px auto !important; 
	float: none !important; 
}

ion-content{
    --ion-background-color:rgba(241, 241, 241, 0.692);
}